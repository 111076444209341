import flat from 'flat'

import frMessages from './lang/fr'

const defaultMessages = {
  fr: frMessages,
}

const messages: { fr: Record<string, string> } = {
  fr: flat(defaultMessages.fr, {
    delimiter: '_',
  }),
}

export default messages
