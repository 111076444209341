import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from './src/theme/GlobalStyle'
import { defaultTheme } from './src/theme/Theme'
import LanguageContextProvider from './src/i18n/LanguageContext'
import Layout from './src/components/Layout/Layout'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  // props: { location },
}) => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <GlobalStyle />
        <LanguageContextProvider>
          <Layout>{element}</Layout>
        </LanguageContextProvider>
      </ThemeProvider>
    </React.StrictMode>
  )
}
