import { createGlobalStyle } from 'styled-components'

import CSSReset from './CSSReset'
import { ThemeContainer } from './Theme'
import device from './utils/device'
import { BROWSER_DEFAULT_FONT_SIZE } from './utils/pixelsToRems'

const GlobalStyle = createGlobalStyle`
  ${CSSReset}

  html, body {
    font-family: var(--font-family);
    font-size: ${BROWSER_DEFAULT_FONT_SIZE}px;
    background-color: white;

    @media ${device.xsmall} {
      font-size: 14px;
    }

    @media ${device.small} {
      font-size: 14px;
    }
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    min-height: 100vh;
  }
  #gatsby-focus-wrapper {
    display: flex;
    flex-direction: column;
  }

  .container {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    padding-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    box-sizing: border-box;
  }

  .container {
    flex: 1;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding-left: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    padding-right: ${({ theme }: ThemeContainer) => theme.spacing.medium};
    box-sizing: border-box;
  }

  :root {
    /* ********** */
    /*   COLORS   */
    /* ********** */

    // Text

    /* ********** */
    /* TYPOGRAPHY */
    /* ********** */

    --font-family: ${({ theme }: ThemeContainer) =>
      theme.typography.fontFamily.IBMPlex.name};

    // Weights

    // Sizes

    // Line-Heights
  }
`

export default GlobalStyle
