import { FunctionComponent } from 'react'
import styled from 'styled-components'
import pixelsToRems from '../../theme/utils/pixelsToRems'
import { ThemeContainer } from '../../theme/Theme'
import device, { size } from '../../theme/utils/device'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import HeaderMobileMenu from './HeaderMobileMenu'
import LogoLink from '../LogoLink/LogoLink'

export const HEADER_HEIGHT = pixelsToRems(80)

const Container = styled.header`
  display: flex;
  flex-direction: column;
  height: ${HEADER_HEIGHT};
  background-color: #ffffff;
  align-items: center;

  .mobile-menu {
    display: none;
  }

  @media ${device.xsmall} {
    .mobile-menu {
      display: block;
    }
  }
  @media ${device.small} {
    .mobile-menu {
      display: block;
    }
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const Nav = styled.nav`
  display: flex;
  height: 100%;

  @media ${device.xsmall} {
    display: none;
  }
  @media ${device.small} {
    display: none;
  }
`

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (min-width: ${size.lg}px) {
    display: flex;
  }

  li:not(:last-child) {
    margin-right: ${({ theme }: ThemeContainer) => theme.spacing.small};
  }
`

const ListItem = styled.li`
  display: inline-flex;
`

const StyledLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${({ theme }: ThemeContainer) =>
    theme.typography.fontFamily.IBMPlex.name};
  font-weight: ${({ theme }: ThemeContainer) =>
    theme.typography.fontFamily.IBMPlex.weight.regular};
  font-size: ${({ theme }: ThemeContainer) => theme.typography.fontSize.large};
  color: var(--text);
  padding: ${({ theme }: ThemeContainer) => theme.spacing.small};
  text-decoration: none;
  border-bottom: 3px solid transparent;

  &:hover,
  &:focus {
    border-color: ${({ theme }: ThemeContainer) => theme.colors.grey};
  }

  &:focus-visible {
    outline-width: 0;
  }

  &.active {
    border-color: ${({ theme }: ThemeContainer) => theme.colors.orange};
  }
`

const StyledMobileLink = styled(StyledLink)`
  justify-content: flex-start;
  font-size: ${({ theme }: ThemeContainer) =>
    theme.typography.fontSize.heading3};
  border-bottom: 2px solid transparent;
`

const Header: FunctionComponent = () => {
  return (
    <Container>
      <Content className="container">
        <LogoLink />
        <Nav aria-labelledby="website-header">
          <List>
            <ListItem>
              <StyledLink to="/" activeClassName="active">
                <FormattedMessage id="global_header_homepage" />
              </StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/nos-produits" activeClassName="active">
                <FormattedMessage id="global_header_ourProducts" />
              </StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/boutique" activeClassName="active">
                <FormattedMessage id="global_header_shop" />
              </StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/contact" activeClassName="active">
                <FormattedMessage id="global_header_contact" />
              </StyledLink>
            </ListItem>
          </List>
        </Nav>
        <HeaderMobileMenu>
          <ListItem>
            <StyledMobileLink to="/" activeClassName="active">
              <FormattedMessage id="global_header_homepage" />
            </StyledMobileLink>
          </ListItem>
          <ListItem>
            <StyledMobileLink to="/nos-produits" activeClassName="active">
              <FormattedMessage id="global_header_ourProducts" />
            </StyledMobileLink>
          </ListItem>
          <ListItem>
            <StyledMobileLink to="/boutique" activeClassName="active">
              <FormattedMessage id="global_header_shop" />
            </StyledMobileLink>
          </ListItem>
          <ListItem>
            <StyledMobileLink to="/contact" activeClassName="active">
              <FormattedMessage id="global_header_contact" />
            </StyledMobileLink>
          </ListItem>
        </HeaderMobileMenu>
      </Content>
    </Container>
  )
}

export default Header
