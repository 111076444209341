import { ThemeProps } from 'styled-components'
import pixelsToRems from './utils/pixelsToRems'

export enum FontFamily {
  IBMPlex,
}

export interface FontSpecs {
  name: string
  weight: Record<string, string>
}

export enum FontSize {
  heading1,
  heading2,
  heading3,
  xlarge,
  large,
  medium,
  small,
  xsmall,
}

export enum LineHeight {
  heading1,
  heading2,
  heading3,
  xlarge,
  large,
  medium,
  small,
}

export interface Typography {
  fontFamily: Record<keyof typeof FontFamily, FontSpecs>
  fontSize: Record<keyof typeof FontSize, string>
  lineHeight: Record<keyof typeof LineHeight, string>
}

export enum ColorName {
  lightGrey,
  grey,
  darkGrey,
  darkBlue,
  orange,
  orangeRed,
}

export enum SpacingSize {
  xsmall,
  small,
  medium,
  large,
  xlarge,
  huge,
}

export interface Theme {
  typography: Typography
  colors: Record<keyof typeof ColorName, string>
  spacing: Record<keyof typeof SpacingSize, string>
}

export type ThemeContainer = ThemeProps<Theme>

export const defaultTheme: Theme = {
  typography: {
    fontFamily: {
      IBMPlex: {
        name: 'IBM Plex Sans',
        weight: {
          regular: 'normal',
          medium: '500',
          bold: '600',
        },
      },
    },
    fontSize: {
      heading1: pixelsToRems(36),
      heading2: pixelsToRems(30),
      heading3: pixelsToRems(28),
      xlarge: pixelsToRems(24),
      large: pixelsToRems(18),
      medium: pixelsToRems(16),
      small: pixelsToRems(14),
      xsmall: pixelsToRems(12),
    },
    lineHeight: {
      heading1: pixelsToRems(60),
      heading2: pixelsToRems(50),
      heading3: pixelsToRems(45),
      xlarge: pixelsToRems(41),
      large: pixelsToRems(32),
      medium: pixelsToRems(28),
      small: pixelsToRems(28),
    },
  },
  colors: {
    lightGrey: '#faf8f6',
    grey: '#636060',
    darkGrey: '#2e2d2d',
    darkBlue: '#3e88a3',
    orange: '#d5803b',
    orangeRed: '#a24c48',
  },
  spacing: {
    xsmall: pixelsToRems(8),
    small: pixelsToRems(16),
    medium: pixelsToRems(32),
    large: pixelsToRems(40),
    xlarge: pixelsToRems(80),
    huge: pixelsToRems(100),
  },
}
