import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ThemeContainer } from '../../theme/Theme'
import IconFacebook from '../icons/IconFacebook'
import IconTwitter from '../icons/IconTwitter'
import { FormattedMessage } from 'react-intl'
import { graphql, useStaticQuery } from 'gatsby'

const Container = styled.div`
  padding: ${({ theme }: ThemeContainer) => theme.spacing.small};
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Copyrights = styled.div``

const SocialIcons = styled.div`
  & > a {
    &:not(:last-child) {
      margin-right: ${({ theme }: ThemeContainer) => theme.spacing.small};
    }
  }
`

const SocialLinks = styled.a`
  & > svg {
    height: 15px;
    width: 15px;
    fill: #808080 !important;
  }

  &:hover {
    & > svg {
      fill: black !important;
    }
  }
`

interface Data {
  file: {
    childMarkdownRemark: {
      frontmatter: {
        facebookPage: string
        twitterAccount: string
      }
    }
  }
}

const Footer: FunctionComponent = () => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery<Data>(graphql`
    query SocialMedias {
      file(relativePath: { glob: "**/settings/socialMedias.md" }) {
        childMarkdownRemark {
          frontmatter {
            facebookPage
            twitterAccount
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Content className="container">
        <Copyrights>
          <FormattedMessage id="global_footer_copyrights" /> {currentYear}
        </Copyrights>
        <SocialIcons>
          {data.file.childMarkdownRemark.frontmatter.facebookPage && (
            <SocialLinks
              href={data.file.childMarkdownRemark.frontmatter.facebookPage}
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconFacebook />
            </SocialLinks>
          )}
          {data.file.childMarkdownRemark.frontmatter.twitterAccount && (
            <SocialLinks
              href={data.file.childMarkdownRemark.frontmatter.twitterAccount}
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconTwitter />
            </SocialLinks>
          )}
        </SocialIcons>
      </Content>
    </Container>
  )
}

export default Footer
