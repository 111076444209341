import { FunctionComponent, ReactNode, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import IconBars from '../icons/IconBars'
import { Theme, ThemeContainer } from '../../theme/Theme'
import ReactPortal from '../ReactPortal/ReactPortal'
import useHeaderMobileMenu from './hooks/useHeaderMobileMenu'
import classNames from 'classnames'
import IconClose from '../icons/IconClose'
import { useLocationContext } from '../Layout/Layout'

const Container = styled.div``

const IconButton = styled.div`
  cursor: pointer;

  & > svg {
    height: 40px;
    width: 40px;
  }
`

const Overlay = styled.div`
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  animation-duration: 0.5s;
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-timing-function: var(--ease-in-quint);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.3);
  will-change: backdrop-filter, background-color;
  z-index: 10;

  @keyframes fade-in {
    0% {
      backdrop-filter: blur(0px);
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes fade-out {
    0% {
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
    }
    100% {
      backdrop-filter: blur(0px);
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &.willClose {
    animation-duration: 0.3s;
    animation-name: fade-out;
    animation-iteration-count: 1;
    animation-timing-function: var(--ease-out-quint);
    backdrop-filter: blur(0px);
    background-color: rgba(0, 0, 0, 0);
  }
`

const Menu = styled.nav`
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 400px;
  z-index: 11;
  background-color: white;
  padding: ${({ theme }: ThemeContainer) => theme.spacing.small};
  box-sizing: border-box;

  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-iteration-count: 1;

  @keyframes slide-in {
    0% {
      right: -400px;
    }
    100% {
      right: 0px;
    }
  }

  @keyframes slide-out {
    0% {
      right: 0px;
    }
    100% {
      right: -400px;
    }
  }

  &.willClose {
    animation-duration: 0.3s;
    animation-name: slide-out;
    animation-iteration-count: 1;
    animation-timing-function: var(--ease-in-quint);
    right: -400px;
  }
`

const MenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const MenuContent = styled.ul`
  display: flex;
  flex-direction: column;
`

export interface Props {
  children: ReactNode
}

const HeaderMobileMenu: FunctionComponent<Props> = ({ children }) => {
  const theme = useTheme() as Theme
  const {
    selectors: { open, willClose },
    actions: { handleMenuOpen, handleMenuClose },
  } = useHeaderMobileMenu()
  const { pathname } = useLocationContext()

  useEffect(() => {
    handleMenuClose()
  }, [pathname])

  return (
    <Container className="mobile-menu">
      <IconButton onClick={handleMenuOpen}>
        <IconBars color={theme.colors.grey} />
      </IconButton>
      <ReactPortal>
        {open && (
          <Overlay
            className={classNames({
              willClose,
            })}
            onClick={handleMenuClose}
          />
        )}
        {open && (
          <Menu
            className={classNames({
              willClose,
            })}
          >
            <MenuHeader>
              <IconButton onClick={handleMenuClose}>
                <IconClose color={theme.colors.grey} />
              </IconButton>
            </MenuHeader>
            <MenuContent>{children}</MenuContent>
          </Menu>
        )}
      </ReactPortal>
    </Container>
  )
}

export default HeaderMobileMenu
