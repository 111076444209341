import { Link } from 'gatsby'
import { FunctionComponent } from 'react'
import styled from 'styled-components'

import { ThemeContainer } from '../../theme/Theme'

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }: ThemeContainer) =>
    theme.typography.fontSize.heading3};
  text-decoration: none;
  color: ${({ theme }: ThemeContainer) => theme.colors.grey};
  white-space: nowrap;
`

const LogoImage = styled.img`
  height: 60px;
  margin-right: ${({ theme }: ThemeContainer) => theme.spacing.small};
`

const LogoLink: FunctionComponent = () => {
  return (
    <Container to="/">
      <LogoImage src="/assets/logo.png" alt="Logo" />
      <span>BOIS FRANC DR</span>
    </Container>
  )
}

export default LogoLink
