const fr = {
  header: {
    homepage: 'Accueil',
    ourProducts: 'Nos Produits',
    shop: 'Boutique',
    contact: 'Contact',
  },
  footer: {
    copyrights: '© – Bois Franc D.R. Inc.',
  },
}

export default fr
